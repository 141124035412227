// import logo from './logo.svg'; cool that you can import svgs!.
import './App.css';

import { useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { useMediaQuery } from 'react-responsive'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, Table } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { Link as NavLink } from "react-scroll";

import FishyFont from './fonts/Something Fishy_Font.woff2';

// const citations = require("./citations.json");
const team = require("./team.json");

const data = require("./content.json");

const logo_url = "SensFish Logo_transparent_white.png"

const innerPageWidth = "80%"
const innerPageMaxWidth = 1200

const verticalTriggerResolution = 1000

var isMobile = false

const theme = createTheme({
  palette: {
    primary: {
      main: '#2952a2',
    },
    secondary: { //235eae //#2952a2
      main: '#08538a',
    },
    accent: {
      main: '#ffffff',
      // light: '#ffa726',
      // dark: '#ef6c00',
      // contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  // typography: {
  //   fontFamily: 'Something Fishy_Font',
  // },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Something Fishy_Font';
          font-style: normal;
          font-display: swap;
          
          src: url(${FishyFont}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

const textStyle = {lineHeight:1.6, fontWeight: 500, fontFamily:"Archivo, Helvetica, Arial, sans-serif", fontSize:"1.1rem", textAlign: "justify"}

const mail = <div style={{marginTop:20, backgroundColor:"#ffffffa0", width:200, padding:10, textAlign:"center"}}><table><tbody><tr>
                  <td style={{textAlign:"right"}}><EmailIcon /></td>
                  <td><Link target='_blank' href="mailto:contact@sensfish.co.uk?subject=New enquiry">contact@sensfish.co.uk</Link></td>
                  </tr></tbody></table></div>


function cardHolder(content, image, width = "70%", contentAlign = "left") {

  if (isMobile) {
    width = "100%"
  }

  const gradient = "#ffffff00 10% 12%, #ffffffea 30% 70% , #ffffff00 80% 90%"

  var backgroundStyle = { display: 'flex', marginTop: 5, marginLeft: 0, marginRight: 0, padding:2, paddingRight:0, 
                          backgroundImage: 'linear-gradient(to right, '+gradient+')', // + ( image ? ', url("'+image+'")' : "" ),
                          // backgroundSize: "cover",
                          // backgroundPositionY:"50%",
                          // backgroundRepeat: "no-repeat",
                          backgroundColor: isMobile ? "#ffffffff" : "#ffffff00",
                          borderRadius:0,
                          boxShadow: "none"
                        }

  var contentStyle = { display: 'flex', flexDirection: 'column', 
                      padding:1, width: width
                    }

  if( contentAlign === "right"){

    backgroundStyle.backgroundImage = 'linear-gradient(to left, '+gradient+')'

  }

  backgroundStyle.paddingLeft = isMobile ? "0%" : "5%"
  backgroundStyle.paddingRight = isMobile ? "0%" : "5%"
  backgroundStyle.paddingTop = "0"
  backgroundStyle.paddingBottom = "0"
  

  const imageContent = <Box sx={{...contentStyle, width: (100-parseInt(width)+"%") }}>
                        <div style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat", backgroundImage: 'url("'+image+'")' , width:"100%", height:"100%"}} > </div>
                      </Box>


  return <Card sx={backgroundStyle}>
            <div style={{width: isMobile ? "100%" : innerPageWidth, 
                        maxWidth:innerPageMaxWidth, 
                        marginLeft:"auto", marginRight:"auto", backgroundColor:"#ffffffff",display: "flex",
                        justifyContent: "space-between"}}>
              

              {
                ( contentAlign !== "left") && image ? imageContent : ""
              }
              
              <Box sx={{...contentStyle }}>
                {content}
              </Box>
              
              {
                ( contentAlign === "left") && image ? imageContent : ""
              }

            </div>
          </Card>

}



function contactCard(){

  return cardHolder(<CardContent sx={{ flex: '1 0 auto', textAlign: "left" }}>
                      
                      <Typography component="div" variant="h4" color="secondary" sx={{marginBottom:2}}>
                        <div> <b>SensFish Limited</b> </div>
                      </Typography>

                      <Typography component="div" variant="h5" color="primary" sx={{marginBottom:2}}>
                        <div>Glasgow, Scotland</div>
                        <br/>
                        <div><EmailIcon /> <Link target='_blank' href="mailto:contact@sensfish.co.uk?subject=New enquiry">contact@sensfish.co.uk</Link></div>
                        {/* <div><TwitterIcon /> <Link target='_blank' href="https://twitter.com/k">@</Link></div>
                        <div><LinkedInIcon /> <Link target='_blank' href="https://www.linkedin.com/company/sensfish/">/sensfish</Link></div> */}
                      </Typography>
                    </CardContent>)
}

function offerCard( content, contentAlign= "right" ){
  
  return cardHolder(<CardContent sx={{ flex: '1 0 auto', textAlign: "left" }}>
                      {/* { content.title ? <Typography component="div" variant="h3" color="secondary" fontFamily="Something Fishy_Font" sx={{marginBottom:2}} dangerouslySetInnerHTML={{__html:content.title}} /> : "" } */}
                      { content.title ? <h1 className='offerHeading' style={{color: theme.palette.secondary.main}}>{content.title} </h1> : "" }
                      { content.body ? <Typography className="cardText" variant="body1" color="common.black" component="div"  
                      dangerouslySetInnerHTML={{__html:content.body}} 
                      sx={{...textStyle}}/> : ""}                    
                      { content.mail ? mail : "" }
                    </CardContent>, content.image, content.width, contentAlign )
}


function teamCard( team, mobile=false, title = "Team", imageSize = 150 ){

  var content = <Table style={{width:"100%", verticalAlign: "top"}}>
        <tbody>{                      
          team.map( (teamMember,c) => {


            var content = <td key={"team_content_"+c} colSpan={teamMember.image ? 1 : 2} style={{verticalAlign:"top", 
                                                              textAlign:"left", 
                                                              paddingBottom:15, 
                                                              width:"60%",
                                                              paddingLeft: (teamMember.rightAlign ? "0px" : "15px")}}>
                            <h1 style={{marginTop:0, marginBottom:0, lineHeight:1}}><b>{teamMember.name}</b></h1>
                            <div style={{fontWeight:"bold"}}><h2 >{teamMember.role}</h2></div>
                            <div style={{color:"black", paddingRight:50, ...textStyle}}>{teamMember.bio}</div>
                          </td>


            var image = <td key={"team_image_"+c} style={{width:"15%", verticalAlign:"top", textAlign:"center"}}>
                          {teamMember.image ? <img src={teamMember.image} style={{width:"100%", height:"100%",maxWidth:imageSize,maxHeight:imageSize}} alt={teamMember.name+"'s photo"}></img> : ""}
                        </td> 

            if ( teamMember.image ){
              content = teamMember.rightAlign ? [content,image] : [image, content]
            }

            return <tr key={"team_"+c} style={{borderBottom:"20px #ffffff00 solid"}}>
                {content}
              </tr>
          })
        }</tbody>
        </Table>


  if ( mobile ) {
    content = <div>
        {
          team.map( (teamMember,c) => {
            return <div key={"mobile_team_"+c} style={{textAlign:"center"}}>
              {teamMember.image ? <img src={teamMember.image} style={{width:250}} alt={teamMember.name+"'s photo"}></img> :""}
              <div><b>{teamMember.name}</b></div>
              <div style={{fontWeight:"bold"}}>{teamMember.role}</div>
              <div style={{color:"black", paddingTop:10, textAlign:"left"}}>{teamMember.bio}</div>
              <hr/>
              </div>
          })
        }
      </div>
  }


  return cardHolder(<CardContent style={{textAlign:"left"}} >
                      <h1 name={title} className='offerHeading' style={{marginLeft: 5}}>{title}</h1>
                      <Typography component="div" variant="body1" color="secondary" sx={{marginBottom:2, lineHeight:1.6, fontWeight: 500, fontSize: "1.1rem"}} > 
                      {content}
                      </Typography>
                    </CardContent>, null, "100%")
}


function partnersCard(collaborators, isBigScreen, title = "Partners", width = "100%" ){
 
 var content =  <div style={{display:"flex", flexWrap:"wrap", alignItems: "center", width: width, justifyContent:"space-evenly"}}>
   
   {collaborators.map( (collaborator,c) => <div style={{flexGrow: 1, textAlign:"center"}} key={"coll_"+c}><img  alt={collaborator.name+"'s photo"} src={collaborator.image} style={{maxWidth:200}} /></div>)}
   
 </div>
 
 return cardHolder(<CardContent style={{textAlign:"left"}} >
                        <h1 name={title} className='offerHeading' style={{marginLeft: 5}}>{title}</h1>
                        <Typography component="div" variant="body1" color="secondary" sx={{marginBottom:2, lineHeight:1.6, fontWeight: 500, fontSize: "1.1rem"}} > 
                        {content}
                        </Typography>
                      </CardContent>, null, width )

}



// function groupTitles(id,title){
//   return <div>
//       <div id={id} ></div>
//       <Typography component="div" variant="h4" color="accent.main"  sx={{fontSize: "2.5rem",marginLeft:1,marginTop:3, marginBottom:2, textAlign:"left", fontWeight:"bold"}} > {title} </Typography>
//     </div>
// }


function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  
  const isBigScreen = useMediaQuery({ query: '(min-width: '+verticalTriggerResolution+'px)' })

  isMobile = !isBigScreen

  const titles = [...data.content.map( (c) => {
    return {title: c.title.split("-")[0], link: c.link}}),
     {title:"Team",link:"Team"}, 
     {title:"Partners",link:"Partners"}, 
     {title:"Contact",link:"contact"}]


  var appBar = <AppBar position="fixed" className='appbar' color="secondary">
                <Toolbar >
                  <Typography variant="h6" color="accent.main" component="div" 
                  sx={{ flexGrow: 1, paddingTop:1, fontWeight:700, fontFamily: 'Helvetica', textTransform:'uppercase' }}>
                    <NavLink to={"top"} className='barLink' >
                      <span style={{ verticalAlign: "middle"}}>
                        <img src={logo_url} 
                              className="App-logo" 
                              alt="logo" 
                              style={{height:40, 
                              marginLeft:0, 
                              marginTop:0}}/>
                        </span>
                    </NavLink>
                    <span style={{marginLeft:100, minWidth:850, marginTop:10}}>
                    {
                      
                      titles.map( 
                          (linkData) => 
                            <NavLink 
                              className='barLink'
                              key={linkData.title}
                              activeClass='active' 
                              spy 
                              to={linkData.link} 
                              offset={-70}
                            >
                                {linkData.title} 
                            </NavLink> 
                        )
                    }
                    </span>
                  </Typography>
                </Toolbar>
              </AppBar>


  if ( !isBigScreen ){
    appBar  = <AppBar position="fixed" className='appbar' color="secondary" sx={{color:"black"}}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsDrawerOpen(true)}            
          >
            <MenuIcon />
          </IconButton>
         
          <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <List >

              
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize:"1rem" }}>
                  
                  {
                    titles.map( 
                        (linkData, l) => 
                        <ListItem key={"navitem-"+l} ><NavLink 
                            className='barLink'
                            key={linkData.title}
                            activeClass='active' 
                            spy 
                            to={linkData.link} 
                            offset={-70}
                            onClick={ () => {setIsDrawerOpen(false);}}
                          >
                              {linkData.title} 
                          </NavLink> 
                          </ListItem>
                      )
                  }
                  
                </Typography>

            </List>
          </Drawer>

          
        </Toolbar>

        <span style={{float:"right", position:'absolute', right:0, margin:12}}><img src={logo_url} className="App-logo" alt="logo" style={{height:35, marginLeft:0}}/></span>
      </AppBar>
      }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      {appBar}

      <div className='page_container'>
          
          {
           data.content.map( (cont, c) => <div key={cont.link+"_"+c} name={cont.link} className="element">{offerCard(cont, cont.side)}</div> )
          }

          {
            teamCard(team.team, !isBigScreen, "Team", 200)
          }

          
          {
            partnersCard(team.collaborators,!isBigScreen, "Partners", "100%" )
          }
            
          <span name="contact">{contactCard()}</span>
          
          <div id="downHere" style={{marginBottom:5, marginTop:700}}></div>
      </div>

      <Card style={{borderRadius:0}}> 
        <h4 style={{ marginTop: 5, marginBottom: 5}}>
          {"© 2023-"+( new Date().getFullYear()+1 )  +" SensFish. Company no: SC763331. All Rights Reserved."}
          </h4>
      </Card>
      </ThemeProvider>
      
    </div>
  );
}

export default App;


